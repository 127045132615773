$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$accuzip-blue: (
  50: #e1f4fc,
  100: #b2e3f7,
  200: #80d0f1,
  300: #51beeb,
  400: #2fb0e8,
  500: #11a3e5,
  600: #0c95d6,
  700: #0582c3,
  800: #0572af,
  900: #00528d, // Exact Color
  A100: #badeff,
  A200: #8dcaff,
  A400: #36a4ff,
  A700: #0c74dd,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$accuzip-teal: (
  50: #e0f7f9,
  100: #b2eaf0,
  200: #7fdde7,
  300: #4ccfdd,
  400: #25c5d6,
  500: #00bacf,
  600: #00aabd,
  700: #0095a3, // Exact Color
  800: #01818b,
  900: #025e61,
  A100: #b0f6fe,
  A200: #76f1ff,
  A400: #00e4fe,
  A700: #00b5cb,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$accuzip-cyan: (
  50: #e0f4fd,
  100: #b0e2f9,
  200: #7bd0f5,
  300: #43bdf1,
  400: #00afef, // Exact Color
  500: #00a1ec,
  600: #0094de,
  700: #0081ca,
  800: #0070b6,
  900: #005195,
  A100: #b2e7ff,
  A200: #7fd8ff,
  A400: #05bcff,
  A700: #008edc,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$accuzip-green: (
  50: #e7f5e6,
  100: #c7e5c2,
  200: #a3d59b,
  300: #7dc572, // Exact Color
  400: #60b953,
  500: #47ac35,
  600: #3d9d2d,
  700: #318b22,
  800: #257a18,
  900: #0e5c04,
  A100: #d0ffc2,
  A200: #adff98,
  A400: #4bff2f,
  A700: #00d400,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$accuzip-lime: (
  50: #f5f9e5,
  100: #e5efbd,
  200: #d4e592,
  300: #c4db67, // Exact Color
  400: #b8d345,
  500: #adcc20,
  600: #a2bb19,
  700: #93a50f,
  800: #848f05,
  900: #6a6900,
  A100: #f3fec5,
  A200: #e9fc9d,
  A400: #dcfc55,
  A700: #bbd025,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$accuzip-tan: (
  50: #e3dcd2, // Exact Color
  100: #b5a89b,
  200: #807060,
  300: #4f3e2b,
  400: #311f04,
  500: #1e0000,
  600: #1d0000,
  700: #1c0000,
  800: #1c0000,
  900: #1b0000,
  A100: #f3e8dc,
  A200: #e9d9c9,
  A400: #d0b69b,
  A700: #a3896f,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
