// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '~@angular/material/theming';

// Plus imports for other components in your app.
@import './accuzip-palette.scss';
@import './global-styles.scss';

// Import bootstrap
@import 'variables';
@import "../node_modules/bootstrap/scss/bootstrap";

// Import Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// ($font-size, $line-height, $font-weight, $font-family, $letter-spacing);

$livingprint-typography: mat.define-typography-config(
  $font-family: 'Rubik',
  $headline:  mat.define-typography-level(5rem, 1.5, 600),
  $title:  mat.define-typography-level(4rem, 1.5, 500),
  $subheading-2:  mat.define-typography-level(3rem, 1.5, 400),
  $subheading-1:  mat.define-typography-level(2rem, 1.5, 400),
  $body-1:  mat.define-typography-level(1rem, 1.5, 400),
  $body-2:  mat.define-typography-level(1rem, 1.5, 300),
  $caption:  mat.define-typography-level(1rem, 1.5, 400),
  $button:  mat.define-typography-level(1rem, 1.5, 400),
  $input:  mat.define-typography-level(1rem, 1.5, 400),
);

// @include angular-material-typography($livingprint-typography);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core($livingprint-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// The warn palette is optional (defaults to red).
// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
// Define the default theme (same as the example above).
$accuzip-light-theme:   mat-light-theme((
  color: (
    primary: $accuzip-light-primary,
    accent: $accuzip-light-accent,
    neutral: $accuzip-light-neutral,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// Include the default theme styles (color and default density)
@include angular-material-theme($accuzip-light-theme);

// Define an alternate dark theme.
$accuzip-dark-theme:   mat-dark-theme((
  color: (
    primary: $accuzip-dark-primary,
    accent: $accuzip-dark-accent,
    neutral: $accuzip-light-neutral,
  )
));

// Include the dark color styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.accuzip-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.accuzip-dark-theme {
  @include angular-material-color($accuzip-dark-theme);
}

/* You can add global styles to this file, and also import other style files */
html, body { margin: 0; height: 100vh; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
