@import '~@angular/material/theming';
@import './accuzip-palette.scss';

$accuzip-light-primary: mat-palette($accuzip-teal, 700);
$accuzip-light-accent:  mat-palette($accuzip-green, 300);
$accuzip-light-neutral: mat-palette($accuzip-tan, 50);
$accuzip-light-background: mat-palette($accuzip-cyan, 50);

$accuzip-dark-primary: mat-palette($accuzip-teal, 700);
$accuzip-dark-accent:  mat-palette($accuzip-cyan, 400);
$accuzip-dark-neutral: mat-palette($accuzip-tan, 50);
$accuzip-dark-background: mat-palette($accuzip-blue, 900);

$primary: mat-color($accuzip-light-primary);
$accent: mat-color($accuzip-light-accent);
$neutral: mat-color($accuzip-light-neutral);
$background: mat-color($accuzip-light-background);
$dark-background: mat-color($accuzip-dark-background);
$dark-accent: mat-color($accuzip-dark-accent);


.registered-trademark {
  font-size: 50%;
}

.contentWrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.titleText {
  font-size: 2.5rem;
  color: $primary;
  padding-top: 20px;
  line-height: 150%;
}

.subtitleText {
  font-size: 1.875rem;
  color: $primary;
}

.bodyText {
  font-size: 1rem;
}

.errorText {
  font-size: 1rem;
  color: red;
}

.spacer {
  padding: 10px;
}

app-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

mat-sidenav-content {
  overflow: visible;
}

.stepIcon {
  height: 100px;
  width: 100px;
  font-size: 100px;
  color: $primary;
}
